import React from 'react';
import { AccountRouteController } from '../../route';
import { ApplicationEntryEnhancer } from '../../entrypoint';
import { useAccountContextV2 } from '../../context';
import { useUserApi } from '../..';
import { useLocation } from 'react-router';
import { useNavigate } from 'react-router';
import { PageIds } from '../../config';


export function createAssetClaimsEnhancer(): ApplicationEntryEnhancer {
    return (create) => (config) => {
        function enhanceController(
            controller: AccountRouteController
        ): AccountRouteController {
            return {
                ...controller,
                useProps(...args) {
                    const viewProps = controller.useProps(...args);
                    const {
                        data: { account: organization },
                    } = useAccountContextV2();
                    const userApi = useUserApi();
                    const currentLocation = useLocation();
                    const navigate = useNavigate();
                    const currentWorkspace = userApi.getCurrentWorkspace();
                    React.useEffect(() => {
                        if (currentWorkspace?.claim.kind === 'inactive' && !currentLocation.pathname.endsWith('/settings/general-settings')) {
                            return navigate(`/u/assets/${currentWorkspace.id}/settings/general-settings`, {
                                replace: true,
                            });
                        }
                    }, [currentWorkspace]);

                    if (!currentWorkspace) {
                        return viewProps;
                    }
                    if (currentWorkspace.claim.kind === 'inactive') {
                        return {
                            ...viewProps,
                            workspaceUrl: `/u/assets/${currentWorkspace.id}/settings/general-settings`,
                            dashboardUrl: null,
                            homeUrl: `/u/assets/${currentWorkspace.id}/settings/general-settings`,
                            creativeCoOpUrl: null,
                            researchUrl: null,
                            insightsUrl: null,
                            reportsUrl: null,
                            peersUrl: null,
                            expertCallUrl: null,
                            
                        };
                    }
                    return viewProps;
                },
            };
        }

        return create({
            ...config,
            // strategy: {
            //     ...config.strategy,
            //     settings: {
            //         ...config.strategy.settings,
            //         asset: {
            //             ...config.strategy.settings.asset,
            //             createDataSource(datasourceInit) {
            //                 const retVal = config.strategy.settings.asset.createDataSource(datasourceInit);
            //                 return {
            //                     create(dsConfig) {
            //                         const dsCreated = retVal.create(dsConfig);
            //                         dsCreated.map(x => x.Component)
            //                     },
            //                 }
            //             },
            //         }
            //     }
            // },
            controller: {
                ...config.controller,
                settings: {
                    ...config.controller.settings,
                    createAsset(...args) {
                        const controller =
                            config.controller.settings.createAsset(...args);
                        return {
                            useProps() {
                                const props = controller.useProps();
                                
                                const userApi = useUserApi();
                                const currentWorkspace = userApi.getCurrentWorkspace();
                                if (!currentWorkspace) {
                                    return props;
                                }
                                const isActiveClaim = currentWorkspace.claim.kind === 'active';
                                return {
                                    ...props,
                                    isVisible(item) {
                                        if(!isActiveClaim) {
                                            return item.key === 'general';
                                        }
                                        return props.isVisible(item);
                                    },
                                };
                                
                            },
                        };
                    },
                },
            },
            route: {
                ...config.route,
                createAccountWorkspaceRoute(routeConfig) {
                    return config.route.createAccountWorkspaceRoute({
                        ...routeConfig,
                        providers: {
                            ...routeConfig.providers,
                            createController(...args) {
                                const controller = routeConfig.providers.createController(
                                    ...args
                                );
                                return enhanceController(controller);
                            },
                        },
                    });
                },
            },
        });
    };
}
