import { AccountExternalInvitation, AnyAccountInvitationEntity, InvitationUser } from './invitationModel';

export function isPlatformInvitation(
    item: AnyAccountInvitationEntity
): item is AccountExternalInvitation & { invitee: null } {
    if(item.kind === 'external') {
        return item.invitee === null
    }
    return false;
}

export function isTeamInvitation(
    item: AnyAccountInvitationEntity
): item is AccountExternalInvitation & { invitee: InvitationUser } {
    if(item.kind === 'external') {
        return !!item.invitee;
    }
    return false;
}

export function isOrganizationInvitation(
    item: AnyAccountInvitationEntity
): item is AccountExternalInvitation & { account: { kind: 'organization', domain: string, name: string }} {
    return item.kind === 'external' && item.account.kind == 'organization';
}
