import { PlatformApi } from './platformInterface';
import { PlatformApiConfig } from './platformConfig';
import { createWorkspaceResource } from './workspace';
import { createCollaboratorResource } from './collaborator';
import { createAssetResource } from './asset/assetResource';
import { createAccountResource } from './account';
import { createUserResource } from './user';
import { createPreferenceResource } from './preference';
import { createRecommendationResource } from './recommendation';
import { createCompetitiveSetResource } from './competitive';
import { createMeResource } from './me';
import { createClientPreferenceResource } from './client-preferences';
import { createInvitationResource } from './invitation';
import { createCountResource } from './count';
import { createPeerSetResource } from './peer-set';
import { createFileResource } from '../files';

export function createPlatformApi(config: PlatformApiConfig): PlatformApi {
    const { client, notAuthenticatedClient } = config;
    const assets = createAssetResource(client);
    return {
        assets,
        me: createMeResource(client, assets),
        account: createAccountResource(client),
        workspaces: createWorkspaceResource(client),
        collaborators: createCollaboratorResource(client),
        users: createUserResource(client),
        preferences: createPreferenceResource(client),
        recommendations: createRecommendationResource(client),
        competitive: createCompetitiveSetResource(client),
        clientPreferences: createClientPreferenceResource(client),
        invitation: createInvitationResource(client, notAuthenticatedClient),
        count: createCountResource(client),
        peerset: createPeerSetResource(client),
        files: createFileResource(client),
    };
}
