import { assert } from '../../../util/assert';
import { RegistrationLoaderConfig } from '../base';
import { RegistrationAcceptCreateLoader } from './registrationAcceptCreateInterface';
import { RegistrationAcceptCreateAggregate } from './registrationAcceptCreateModel';

export function createRegistrationAcceptCreateLoader(
    config: RegistrationLoaderConfig
): RegistrationAcceptCreateLoader {
    const {
        repository: { invitation: invitationRepository },
    } = config;
    return {
        useLoad(context, props): RegistrationAcceptCreateAggregate {
            const invitationQuery = invitationRepository.useVerify(
                {
                    invitationId: props.invitation.id,
                    token: props.token,
                },
                { suspense: true, retry: false }
            );
            assert(invitationQuery.status === 'success', 'expected suspense');
            assert(
                invitationQuery.data.kind === 'external' || invitationQuery.data.kind === 'member',
                'only external invitation supported'
            );

            return {
                invitation: invitationQuery.data,
            };
        },
    };
}
