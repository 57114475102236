import { ApplicationEntryEnhancer } from '../../../../../entrypoint';
import { AnyUserManagmentEvent } from './userManagementTrackingEvent';

export function createSettingsUserManagementTrackingStrategy(): ApplicationEntryEnhancer {
    return (create) => (init) => {
        const useTracker = init.infra.createUseTracker();

        const instance = create({
            ...init,
            route: {
                ...init.route,
                createAccountRoute(routeConfig) {
                    return init.route.createAccountRoute({
                        ...routeConfig,
                        controller: {
                            ...routeConfig.controller,
                            useUserNavigation() {
                                const userNavProps =
                                    routeConfig.controller.useUserNavigation();
                                const tracker = useTracker<AnyUserManagmentEvent>();
                                if(userNavProps.inviteUser) {
                                    
                                        return {
                                            ...userNavProps,
                                            inviteUser: {
                                                ...userNavProps.inviteUser,
                                                props: {
                                                    ...userNavProps.inviteUser.props,
                                                    onSubmit(dto) {
                                                        return userNavProps.inviteUser ? userNavProps.inviteUser.props
                                                            .onSubmit(dto)
                                                            .then((retVal) => {
                                                                tracker.track(
                                                                    'permissions_teammate_invited',
                                                                    {
                                                                        permissions_brand_scope:
                                                                            dto.role == 'admin'
                                                                                ? 'org'
                                                                                : 'asset',
                                                                    }
                                                                );
                                                                return retVal;
                                                            }): Promise.reject('error');
                                                    },
                                                },
                                            },
                                        }
                                } else {
                                    return userNavProps;
                                }
                            },
                        },
                        
                    });
                },
            },
        });
        return instance;
    };
}
