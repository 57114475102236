import { AuthenticatorConfig } from './authenticatorConfig';
import { Authenticator } from './authenticatorInterface';

export function createAuthenticator(config: AuthenticatorConfig): Authenticator {
    return {
        async getToken(context) {
            const token = await config.adapter.getToken(context);
            return token;
        },
    };
}
