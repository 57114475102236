import React from 'react';
import {
    AccountContextAggregate,
    UseAccountContextReturn,
} from './organizationModel';

export const AccountContext =
    React.createContext<AccountContextAggregate | null>(null);

export function useAccountContextV2(): UseAccountContextReturn {
    const value = React.useContext(AccountContext);
    if (!value) {
        throw new Error(`not inside account context`);
    }
    return value;
}
