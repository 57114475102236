import { useUserApi } from '../../app';
import { AccountsHelpers } from '../../domain/accounts';
import { AccountRouteController as AccountRouteController } from './accountRouteInterface';
import { AccountRouteConfig as AccountRouteConfig } from './accountRouteConfig';
import { useNavigate, useParams } from 'react-router-dom';
import { AccountRouteParams } from './accountRouteSchema';
import { useMemo, useEffect } from 'react';
import { AccountRouteProps } from './accountRouteProps';

export function createAccountRouteController(
    config: Pick<AccountRouteConfig, 'controller'>
): AccountRouteController {
    return {
        useProps(data): AccountRouteProps {
            const navigate = useNavigate();
            const user = useUserApi();
            const dataset = config.controller.useVarosDataset();
            const workspaceUrl = user.getDefaultWorkspaceUrl();
            const workspace = user.getCurrentWorkspace();
            // const reportsUrl = `/u/assets/${workspace?.id}/reports/summary`;

            const isAgency = useMemo(() => {
                if (data.item.data?.profile && data.item.data?.assets) {
                    return data.item.data?.profile?.account &&  AccountsHelpers.isAgency({
                        profile: data.item.data?.profile,
                        assets: data.item.data?.assets,
                    });
                }
            }, [data.item.data?.profile, data.item.data?.assets]);
            
            const params = useParams();

            useEffect(() => {
                if(!data.item.data?.profile?.account?.finishedOnboarding) {
                    navigate('/u/onboarding?step=integration', { replace: true });
                }
            }, [data.item.data?.profile]);
            // this is only safe because all the keys are optional
            const parsed = AccountRouteParams.parse(params);
            
            return {
                // reportsUrl,
                workspaceUrl,
                homeUrl: parsed.workspaceId
                    ? `/u/assets/${parsed.workspaceId}/home`
                    : workspaceUrl,
                dashboardUrl: workspace
                    ? `/u/assets/${workspace.id}/dashboards`
                    : workspaceUrl,
                // reportsUrl: workspace
                //     ? `/u/assets/${workspace.id}/opportunities`
                //     : workspaceUrl,
                reportsUrl: workspace
                    ? `/u/assets/${workspace.id}/reporting`
                    : workspaceUrl,
                peersUrl: workspace ? `/u/assets/${workspace.id}/peers` : workspaceUrl,
                insightsUrl: workspace
                    ? `/u/assets/${workspace.id}/opportunities`
                    : workspaceUrl,
                expertCallUrl: workspace
                    ? `/u/assets/${workspace.id}/tactical-calls`
                    : workspaceUrl,
                // reportUrl: isAgency ? '/u/reports' : null,
                // addBrandUrl: isAgency ? '/u/settings/companies/new' : null,
                addBrandUrl: '/u/settings/companies/new',
                creativeCoOpUrl: workspace
                ? `/u/private-groups`
                : null,
                researchUrl: `/u/research`,
                integrationsUrl: "/u/integrations",
                datasetAssetCount: dataset.assetCount ?? 0,
                isVisible(item) {
                    return true;
                },
                getLinkId(id: string) {
                    return `main_navigation_${id}`;
                },
                links: {
                    newCompany: workspace
                        ? {
                              //   id: 'organization_navigation_new_company_link',
                              //   to: '/u/settings/companies/new',
                              onClick() {
                                  navigate('/u/settings/companies/new');
                              },
                          }
                        : null,
                    newIntegration: workspace
                        ? {
                              //   id: 'organization_navigation_new_integration_link',
                              //   to: '/u/integrations',
                              onClick() {
                                  navigate('/u/integrations');
                              },
                          }
                        : null,
                    newGroup: workspace
                        ? {
                              onClick() {
                                  navigate(
                                      `/u/assets/${workspace.id}/peers/competitive_sets/new`
                                  );
                              },
                          }
                        : null,
                },
            };
        },
    };
}
