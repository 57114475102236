import React from 'react';
import { AccountRouteConfig as AccountRouteConfig } from './accountRouteConfig';
import { AccountRouteContext } from './accountRouteInterface';

export function createAccountRoute(
    config: AccountRouteConfig
): React.FC<{ children?: React.ReactNode | undefined } & { children?: React.ReactNode }> {
    const {
        hook,
        providers: { createController, createView, createLoader },
    } = config;
    const controller = createController(config);
    const loader = createLoader(config);
    const View = createView(config);
    return (props) => {
        const auth = hook.useAuth();
        const account = hook.useAccount();
        const viewData = loader.useLoad({ auth, account });
        const viewProps = controller.useProps(viewData);
        return <View {...viewProps}>{props.children}</View>;
    };
}
