import React from 'react';
import { Navigate } from 'react-router';
import { GRAY, VAROS_GREEN } from '../../config';
import {
    createWorkspaceRedirectController,
    NoWorkspaceConfigured,
    Workspace,
} from '../..';

import { createWorkspaceNavigationView } from '../../view';
import { ApplicationEntrypointConfig } from '../../entrypoint';
import { useUserApi, createWorkspaceProvider, WorkspaceProviderConfig } from '../../app';
import {
    createApiDashboardImpl,
    createSubscriptionApiImpl,
    createFeaturesetImpl,
} from '../../impl';
import { UseAccountContextReturn } from '../../context';


export function createWorkspaceDefaultDashboardRedirect({
    deps,
    provider,
}: {
    deps: {
        hook: {
            useAccount(): UseAccountContextReturn;
            useWorkspace(): Workspace | null;
        };
    };
    provider: {
        createController: typeof createWorkspaceRedirectController;
    };
}): React.FC<
    { children?: React.ReactNode | undefined } & { children?: React.ReactNode }
> {
    const controller = provider.createController();

    return () => {
        const {
            data: { account },
        } = deps.hook.useAccount();
        const workspace = deps.hook.useWorkspace();
        const props = controller.useProps({
            workspace,
            account,
        });
        
        const url = props.to;
        if (url) {
            return <Navigate to={url} replace={true} />;
        }
        // shouldn't get here because cases where no workspace are onboarding cases
            return <NoWorkspaceConfigured />;
    };
}

export function configureWorkspaceProvider(
    config: Pick<
        WorkspaceProviderConfig,
        'paramKey' | 'queryKey' | 'workspaceNotFoundPathName'
    >
) {
    return createWorkspaceProvider({
        ...config,
        getDeps: (config) => {
            return {
                adapter: {
                    dashboard: createApiDashboardImpl({ axios: config.api }),
                    subscription: createSubscriptionApiImpl({ axios: config.api }),
                    featureset: createFeaturesetImpl({ axios: config.api }),
                },
            };
        },
    });
}
