import {
    AccountLayoutContainerProps,
    AccountLayoutV2,
} from '../../../../route';
import {
    fetchChildrenProps,
    fetchElements,
    getAllChildrenProps,
    getChildrenProps,
} from '../../../../util';

export function buildLayoutProps(
    Layout: AccountLayoutV2,
    props: AccountLayoutContainerProps
) {
    const { content, navigation } = fetchChildrenProps(props.children, {
        content: Layout.Content,
        navigation: Layout.Navigation.List,
    });

    const { links } = getAllChildrenProps(navigation.children, {
        links: Layout.Navigation.Link,
    });

    return {
        content,
        links,
    };
}
