import { useEffect, useMemo, useState } from 'react';
import {  IS_PRODUCTION } from '../../../../infra';
import { OnboardingPeerGroupContainerConfig } from './onboardingPeerGroupConfig';
import { OnboardingPeerGroupController } from './onboardingPeerGroupInterface';
import { OnboardingPeerGroupViewProps } from './onboardingPeerGroupProps';

export function createOnboardingPeerGroupController(
    config: OnboardingPeerGroupContainerConfig
): OnboardingPeerGroupController {
    const {
        hooks: { useLocalStorage },
    } = config;
    return {
        useProps(context, state, data, props): OnboardingPeerGroupViewProps {
            const selectedPluginId = state.plugin.value ?? props.initialValues.plugin;

            const [, setOnboardingState] = useLocalStorage(
                context.account.id.toString()
            );

            useEffect(() => {
                setOnboardingState({
                    step: 'peer_groups',
                    account: context.account.id,
                    asset: context.workspace.id as number,
                });
            }, []);

            const [selectedIndex, setSelectedIndex] = useState<number | null | undefined>(
                undefined
            );

            const appliedSelectedIndex = useMemo(() => {
                if (typeof selectedIndex === 'number') {
                    return selectedIndex;
                }
                if (selectedIndex === null) {
                    return null;
                }
                if (selectedPluginId) {
                    return (
                        data.items.findIndex(
                            (item) => item.plugin.id === selectedPluginId
                        ) ?? null
                    );
                }
                return null;
            }, [selectedIndex, data.items, selectedPluginId]);

            const counts = {
                total: data.items.length,
                configured: data.items.filter((item) => item.status === 'configured')
                    .length,
                pending: data.items.filter((item) => item.status === 'pending').length,
            };

            return {
                isLoading: data.isLoading,
                emptyState:
                    data.query.plugins.data?.length === 0
                        ? {
                              title: 'No active integrations',
                              description:
                                  'Make sure your integrations are connected and have data',
                          }
                        : null,
                onBack: props.onBack ?? null,
                items: data.items,
                async onContinue(context) {
                    props.onContinue?.(context);
                },
                isComplete: !IS_PRODUCTION || counts.configured === counts.total,
                isVisible(item, index) {
                    // when selected hide all other plugins
                    // return selected === null || index === selected;
                    return true;
                },
                getStatusText() {
                    // if (counts.configured === 0) {
                    //     return `No peer groups configured`;
                    // }
                    return `${counts.configured}/${counts.total} configured`;
                },
                getPluginItemProps(item, index) {
                    return {
                        item,
                        isOpen: index === appliedSelectedIndex,
                        onOpen: setSelectedIndex.bind(
                            null,
                            index === appliedSelectedIndex ? null : index
                        ),
                        onClose: setSelectedIndex.bind(null, null),
                        onApply() {
                            setSelectedIndex(index + 1);
                        },
                    };
                },
            };
        },
    };
}
