import {
    Box,
    Grid,
    GridItem,
    HStack,
    Icon,
    Image,
    TabPanel,
    TabPanels,
    Tabs,
    Text,
    VStack,
} from '@chakra-ui/react';
import varosLogoSVG from '../../../../svg/varos-logo-large.svg';
import React from 'react';
import { RegistrationViewConfig } from '../base';
import { RegistrationAcceptCreateViewProps } from './registrationAcceptCreateProps';
import { AiOutlineArrowLeft, AiOutlineEdit, AiOutlineUser } from 'react-icons/ai';
import { GoCheckCircle, GoCircle, GoOrganization } from 'react-icons/go';

export function createRegistrationAcceptCreateView(
    config: RegistrationViewConfig & {
        View: {
            Welcome: React.FC<
                { children?: React.ReactNode | undefined } & {
                    children?: React.ReactNode;
                }
            >;
            Profile: React.FC<
                { children?: React.ReactNode | undefined } & {
                    children?: React.ReactNode;
                }
            >;
            Organization: React.FC<
                { children?: React.ReactNode | undefined } & {
                    children?: React.ReactNode;
                }
            >;
            Finalize: React.FC<
                { children?: React.ReactNode | undefined } & {
                    children?: React.ReactNode;
                }
            >;
        };
    }
): React.FC<
    { children?: React.ReactNode | undefined } & RegistrationAcceptCreateViewProps
> {
    const {
        View: {
            Welcome: WelcomeView,
            Profile: ProfileView,
            Organization: OrganizationView,
            Finalize: FinalizeView,
        },
        UI: {
            Application: {
                Link,
                Anchor,
                Stepper: {
                    Container: Stepper,
                    Step,
                    Status: StepStatus,
                    Indicator: StepIndicator,
                },
            },
        },
    } = config;

    return (props) => {
        const steps = [
            {
                key: 'welcome',
                title: 'Welcome',
                Icon: AiOutlineUser,
                children: <WelcomeView />,
                isVisible: false,
            },
            {
                key: 'profile',
                title: 'User profile',
                Icon: AiOutlineUser,
                children: <ProfileView />,
            },
            ...props.isOrganization ?([{
                key: 'organization',
                title: 'Organization',
                Icon: GoOrganization,
                children: <OrganizationView />,
            }]): [],
            {
                key: 'finalize',
                title: 'Finalize',
                Icon: AiOutlineEdit,
                children: <FinalizeView />,
                isVisible: false,
            },
        ];
        return (
            <Grid bg="white" color="black" h="full" w="full" templateColumns="2fr 5fr">
                <GridItem p={6}>
                    <Grid
                        w="full"
                        h="full"
                        templateRows="min-content 1fr min-content"
                        bg="gray.50"
                        borderColor="gray.200"
                        borderWidth={1}
                        borderStyle="solid"
                        borderRadius="xl"
                        p={12}
                        gap={12}
                    >
                        <GridItem>
                            <Image
                                {...props.button.home}
                                src={varosLogoSVG}
                                cursor="pointer"
                                maxWidth="100%"
                                width="auto"
                                h={10}
                                objectFit="contain"
                            />
                        </GridItem>
                        <GridItem>
                            <VStack h="full" w="full" align="start" spacing={6}>
                                <HStack>
                                    <Text color="gray.600" fontWeight="semibold">
                                        Account setup
                                    </Text>
                                </HStack>
                                <Stepper {...props.stepper} w="full" spacing={6}>
                                    {steps.map(({ Icon: StepIcon, ...step }) => (
                                        <Step
                                            key={step.key}
                                            w="full"
                                            spacing={3}
                                            opacity={1}
                                            color="gray.800"
                                            isVisible={step.isVisible}
                                            _disabled={{ opacity: 0.3 }}
                                            _current={{ color: 'blue.600' }}
                                        >
                                            <Box
                                                p={2}
                                                borderWidth={1}
                                                bg="white"
                                                borderColor="gray.300"
                                                borderStyle="solid"
                                                borderRadius="md"
                                                shadow="sm"
                                            >
                                                <Icon as={StepIcon} />
                                            </Box>
                                            <HStack
                                                fontWeight="medium"
                                                userSelect="none"
                                                w="full"
                                                justify="space-between"
                                            >
                                                <Text whiteSpace="nowrap">
                                                    {step.title}
                                                </Text>
                                                <StepIndicator
                                                    _checked={{ color: 'green.600' }}
                                                    _current={{ color: 'blue.600' }}
                                                    _disabled={{ color: 'gray.600' }}
                                                >
                                                    <StepStatus
                                                        complete={
                                                            <Icon
                                                                w={5}
                                                                h={5}
                                                                as={GoCheckCircle}
                                                            />
                                                        }
                                                        incomplete={
                                                            <Icon
                                                                w={5}
                                                                h={5}
                                                                as={GoCircle}
                                                            />
                                                        }
                                                        active={
                                                            <Icon
                                                                w={5}
                                                                h={5}
                                                                as={GoCircle}
                                                            />
                                                        }
                                                    />
                                                </StepIndicator>
                                            </HStack>
                                        </Step>
                                    ))}
                                </Stepper>
                            </VStack>
                        </GridItem>
                        <GridItem>
                            <HStack w="full" justify="space-between" fontWeight="medium">
                                <Anchor {...props.anchor.home}>
                                    <HStack
                                        cursor="pointer"
                                        _hover={{ textDecor: 'underline' }}
                                    >
                                        <Icon as={AiOutlineArrowLeft}></Icon>
                                        <Text>Back to home</Text>
                                    </HStack>
                                </Anchor>
                                <Link {...props.link.login}>Sign in</Link>
                            </HStack>
                        </GridItem>
                    </Grid>
                </GridItem>
                <GridItem>
                    <Box h="full" py={16} w="fit-content" mx="auto">
                        <Tabs index={props.stepper.index} isLazy={true}>
                            <TabPanels>
                                {steps.map(({ children, ...rest }) => (
                                    <TabPanel key={rest.key} p={0}>
                                        {children}
                                    </TabPanel>
                                ))}
                            </TabPanels>
                        </Tabs>
                    </Box>
                </GridItem>
            </Grid>
        );
    };
}
