import { assert } from '@varos/util-typescript';
import React from 'react';
import { ReportContextProvider, ReportContext } from './reportContextInterface';
import { ReportContextBaseConfig } from '../../../base';

export function createReportContextProvider(
    config: ReportContextBaseConfig
): ReportContextProvider {
    const Context = React.createContext<ReportContext | null>(null);
    return {
        Provider({ children, ...containerProps }) {
            const auth = config.hook.useAuth();
            assert(auth.user?.id, 'missing user id');
            const value: ReportContext = {
                principal: {
                    kind: 'user',
                    id: auth.user.id,
                },
                organization: null,
            };
            return <Context.Provider value={value}>{children}</Context.Provider>;
        },
        useContext() {
            const context = React.useContext(Context);
            if (!context) {
                throw new Error('not inside report context');
            }
            return context;
        },
    };
}
