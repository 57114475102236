import { assert } from '../../util/assert';
import { AccountRouteConfig } from './accountRouteConfig';
import { AccountRouteLoader } from './accountRouteInterface';

export function createOrganizationRouteLoader(
    config: Pick<AccountRouteConfig, 'repository'>
): AccountRouteLoader {
    const { repository } = config;
    return {
        useLoad(context) {
            const profileQuery = repository.account.profile.useLookup(context, {
                suspense: true,
                staleTime: Infinity,
            });
            const assetQuery = repository.asset.useFind(
                context,
                {},
                {
                    suspense: true,
                    staleTime: Infinity,
                }
            );
            assert(profileQuery.status === 'success', 'expected suspense');
            assert(assetQuery.status === 'success', 'expected suspense');
            return {
                item: {
                    status: 'loaded',
                    data: { profile: profileQuery.data, assets: assetQuery.data },
                },
            };
        },
    };
}
