import { z } from 'zod';
import { AccountProfileSchema } from '../me';
// Enums

export const AccountInvitationStatusSchema = z.union([
    z.literal('pending'),
    z.literal('ready'),
    z.literal('completed'),
    z.literal('canceled'),
]);

// Sources

export const AccountInvitationGroupSourceSchema = z.object({
    kind: z.literal('group'),
    group_ids: z.array(z.string()),
});

export const AccountInvitationAssetSourceSchema = z.object({
    kind: z.literal('asset'),
    asset_ids: z.array(z.number()),
});

export const AccountInvitationSourceSchema = z.discriminatedUnion('kind', [
    AccountInvitationGroupSourceSchema,
    AccountInvitationAssetSourceSchema,
]);

// Actions

export const IngestionImportInvitationActionSchema = z.object({
    kind: z.literal('ingestion_survey'),
    surveys: z.array(z.object({survey_id: z.string(), populate_data: z.record(z.string().nullable())}))
});

export const IngestionIntegrationInvitationActionSchema = z.object({
    kind: z.literal('ingestion_integration'),
    integration_definitions: z.array(z.string()),
});

export const AccountInvitationActionSchema = z.discriminatedUnion('kind', [
    IngestionImportInvitationActionSchema,
    IngestionIntegrationInvitationActionSchema,
]);

export const InvitationMetadata = z.object({
    inviter_user_id: z.number().nullable(),
    inviter_account_id: z.string().nullable(),
    status: AccountInvitationStatusSchema,
    source: z.nullable(AccountInvitationSourceSchema),
    actions: z.nullable(
        z.array(
            z.object({
                spec: AccountInvitationActionSchema,
                scope: z.union([
                    z.literal('any_asset'),
                    z.literal('default_asset'),
                    z.literal('account'),
                    z.object({
                        kind: z.literal('company'),
                        company_id: z.string(),
                    }),
                ]),
                is_required: z.boolean(),
            })
        )
    ),
    sent_count: z.number(),
    last_sent: z.nullable(z.date({ coerce: true })),
});
// Models

export const AccountFuzzyInvitationSchema = z.object({
    kind: z.literal('fuzzy'),
    text: z.string(),
});

export const AccountCompanyInvitationSchema = z.object({
    kind: z.literal('company'),
    company_id: z.string(),
});

export const AccountMemberInvitationSchema = z.object({
    kind: z.literal('member'),
    email: z.string(),
    role: z.union([
        z.literal('admin'),
        z.object({
            kind: z.literal('standard'),
            collaborations: z.nullable(z.array(z.object({
                asset_id: z.number(),
                role: z.enum(['manager', 'viewer'])
            }) ))
        })
    ]),
    user_input: z.nullable(z.string()),
    first_name: z.nullable(z.string()),
    last_name: z.nullable(z.string()),
    member_profile: z.object({
        title: z.string(),
    }).nullable(),
    invitee_user_id: z.nullable(z.number()),
    invitee_account_id: z.nullable(z.string()),
});

export const AccountExternalInvitationSchema = z.object({
    kind: z.literal('external'),
    email: z.string(),
    account: z.discriminatedUnion('kind', [
        z.object({
            kind: z.literal('organization'),
            name: z.string(),
            domain: z.string(),
            profile: AccountProfileSchema
        }),
        z.object({
            kind: z.literal('user'),
            profile: AccountProfileSchema
        }),
    ]),
    first_name: z.nullable(z.string()),
    last_name: z.nullable(z.string()),
    company_id: z.nullable(z.string()),
    invitee_user_id: z.nullable(z.number()),
    invitee_account_id: z.nullable(z.string()),
});

export const AccountAnyInvitationSchema = z.discriminatedUnion('kind', [
    AccountFuzzyInvitationSchema,
    AccountCompanyInvitationSchema,
    AccountMemberInvitationSchema,
    AccountExternalInvitationSchema,
]);

// Responses

export const AccountInvitationSchema = z.object({
    id: z.string(),
    metadata: InvitationMetadata,
    data: AccountAnyInvitationSchema,
    created_at: z.date({ coerce: true }),
    updated_at: z.nullable(z.date({ coerce: true })),
});

export const AccountInvitationListSchema = z.object({
    data: z.array(AccountInvitationSchema),
});
