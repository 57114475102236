import React from 'react';
import { useNavigate } from 'react-router';
import { RegistrationContainerConfig } from '../../base';
import { RegistrationAcceptContextProvider } from '../context';
import { RegistrationAcceptProfileController } from './acceptProfileInterface';
import { AcceptProfileViewProps } from './acceptProfileProps';
import { isOrganizationInvitation } from '../../../../domain/accounts/invitation';
import { buildServicePayload } from './acceptProfileFactory';

export function createRegistrationAcceptProfileContainer(
    config: RegistrationContainerConfig & {
        accept: RegistrationAcceptContextProvider;
    },
    controller: RegistrationAcceptProfileController,
    View: React.FC<{ children?: React.ReactNode | undefined } & AcceptProfileViewProps>
): React.FC<{ children?: React.ReactNode | undefined } & { children?: React.ReactNode }> {
    const {
        infra: { toaster },
        service: { invitation: invitationService },
        accept: { useContext },
    } = config;
    return (_containerProps) => {
        const toast = toaster.useToast();
        const context = useContext();
        const isOrganization = isOrganizationInvitation(context.data.invitation);
        const mutation = invitationService.useAccept();
        
        const props = controller.useProps(context, {
            stepper: context.stepper,
            form: {
                action: {
                    label: isOrganization ? 'Continue': 'Finish',
                },
                id: 'invite_accept_profile_step',
                form: context.form.profile,
                async onSubmit(values) {
                    if(isOrganization) {
                        await new Promise((resolve) => setTimeout(resolve, 500));
                        context.stepper.onChange(context.stepper.selected + 1);
                    } else {
                        const payload = buildServicePayload(context, {
                            step: context.stepper.selected,
                            token: context.token,
                            invitation: context.invitation,
                            profile: context.form.profile.getValues(),
                        });
                        const response = await mutation.mutateAsync(payload);
                        context.stepper.onChange(context.stepper.selected + 1);
                    }
                },
                onSubmitError(error) {
                    if(error.message) {
                        toast({
                            kind: 'error',
                            description: `Form submission failed: ${error.message}`,
                            durationMs: 20000,
                        }); 
                    } else {
                    toast({
                        kind: 'error',
                        description: `Form submission failed`,
                        durationMs: 5000,
                    });
                    }
                },
                metadata: context.metadata,
            },
            layout: {
                stepper: context.stepper,
                navigate: useNavigate(),
            },
            page: {
                metadata: context.metadata,
            },
        });
        return <View {...props} />;
    };
}
