import { Asset, AssetUpdateProps } from '../../../domain/assets';
import { AssetAdapter } from '../../../app/assets';
import { AssetImplConfig } from './assetImplConfig';
import { AssetUpdateDto } from '../../../api/platform';

export function createAssetImpl(config: AssetImplConfig): AssetAdapter {
    const { api } = config;
    return {
        async fetch(context, query) {
            const item = await api.platform.assets.fetch(context, query);
            return {
                id: item.id,
                name: item.name,
                url: item.url,
                visible: item.is_visible,
                type: item.asset_type,
                createdAt: item.created_at,
                updatedAt: item.updated_at,
                claim: item.claim ? (
                    {
                        ...item.claim,
                        ...item.claim.claim
                    }
                ): null,
            }
        },
        async find(context, query) {
            const response = await api.platform.assets.list(context, {
                visible: query.visible ?? undefined,
                expand: query.expand,
                activeClaim: query.activeClaim,
            });
            return {
                total: response.data.length,
                items: response.data.flatMap((item): Asset[] => {
                    if (!item.url) {
                        console.warn(`asset ${item.id} does not have an url`);
                        return [];
                    }
                    return [
                        {
                            id: item.id,
                            name: item.name,
                            url: item.url,
                            visible: item.is_visible,
                            type: item.asset_type,
                            createdAt: item.created_at,
                            updatedAt: item.updated_at,
                            claim: item.claim ? (
                                {
                                    ...item.claim,
                                    ...item.claim.claim
                                }
                            ): null,
                        },
                    ];
                }),
                limit: query.limit,
            };
        },
        async create(context, props) {
            const responseAsset = await api.platform.assets.create(context, {
                name: props.name,
                url: props.url,
                asset_type: props.type,
                is_visible: props.isVisible,
            });

            const account = await api.platform.account.get(
                context,
                context.account.id
            );

            if (account.state.default_asset_id === null) {
                try {
                    console.info(`setting default asset for account: ${responseAsset.id}`);
                    const responseOrg = await api.platform.account.update(
                        context,
                        context.account.id,
                        { default_asset: responseAsset.id }
                    );
                    console.info(`succesfully organization updated`, responseOrg);
                } catch (error) {
                    console.error(`failed to set default asset`, error);
                }
            }

            return {
                ...responseAsset,
                visible: responseAsset.is_visible,
                type: responseAsset.asset_type,
                createdAt: responseAsset.created_at,
                updatedAt: responseAsset.updated_at,
                claim: responseAsset.claim ? (
                    {
                        ...responseAsset.claim,
                        ...responseAsset.claim.claim
                    }
                ): null,
            };
        },
        async update(context, id, props) {
            let add_evidence_payload: AssetUpdateDto['add_evidence'] = null;
            if(props.addEvidence) {
                const file = await config.api.platform.files.upload(context, {
                    file: props.addEvidence.evidence
                });
                add_evidence_payload = {
                    kind: 'file',
                    file_id: file.id,
                    note: props.addEvidence.note
                };
            }
            const response = await api.platform.assets.update(context, id, {
                name: props.name,
                url: props.url,
                asset_type: props.type,
                is_visible: props.isVisible,
                add_evidence: add_evidence_payload
            });
            return {
                ...response,
                visible: response.is_visible,
                type: response.asset_type,
                createdAt: response.created_at,
                updatedAt: response.updated_at,
                claim: response.claim ? (
                    {
                        ...response.claim,
                        ...response.claim.claim
                    }
                ): null,
            };
        },
    };
}
