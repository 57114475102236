import React from 'react';
import { AccountRouteController } from '../../route';
import { ApplicationEntryEnhancer } from '../../entrypoint';
import { useAccountContextV2 } from '../../context';
import { Navigate } from 'react-router';


export function createAccountProfileEnhancer(): ApplicationEntryEnhancer {
    return (create) => (config) => {
        function enhanceController(
            controller: AccountRouteController
        ): AccountRouteController {
            return {
                ...controller,
                useProps(...args) {
                    const viewProps = controller.useProps(...args);
                    const {
                        data: { account },
                    } = useAccountContextV2();
                    if (account.details.kind === 'organization') {
                        return viewProps;
                    }
                    else {
                        return {
                            ...viewProps,
                            workspaceUrl: null,
                            dashboardUrl: null,
                            homeUrl: '/u/account/reporting',
                            creativeCoOpUrl: null,
                            researchUrl: null,
                            insightsUrl: null,
                            hideContext: false,
                            reportsUrl: '/u/account/reporting',
                            peersUrl: null,
                            expertCallUrl: null,
                            integrationsUrl: null,
                            links: {
                                newCompany: null,
                                newGroup: null,
                                newIntegration: null
                            }
                        }
                    }                
                },
            };
        }

        return create({
            ...config,
            // redirect: {
            //     ...config.redirect,
            //     createRoot(rootConfig) {
            //         return config.redirect.createRoot({
            //             ...rootConfig,
            //             provider: {
            //                 createController() {
            //                     const underlyingController =
            //                         rootConfig.provider.createController();
            //                     return {
            //                         useProps(controllerProps) {
            //                             if (controllerProps.workspace) {
                                            
            //                                 if (pendingAction) {
            //                                     return {
            //                                         to: pendingAction.route,
            //                                     };
            //                                 }
            //                             }
            //                             return underlyingController.useProps(
            //                                 controllerProps
            //                             );
            //                         },
            //                     };
            //                 },
            //             },
            //         });
            //     },
            // },
            route: {
                ...config.route,
                createAccountRoute(routeConfig) {
                    return config.route.createAccountRoute({
                        ...routeConfig,
                        providers: {
                            ...routeConfig.providers,
                            createController(...args) {
                                const controller = routeConfig.providers.createController(
                                    ...args
                                );
                                return enhanceController(controller);
                            },
                        },
                    });
                },
                createAccountWorkspaceRoute(routeConfig) {
                    return config.route.createAccountWorkspaceRoute({
                        ...routeConfig,
                        providers: {
                            ...routeConfig.providers,
                            createController(...args) {
                                const controller = routeConfig.providers.createController(
                                    ...args
                                );
                                return enhanceController(controller);
                            },
                        },
                    });
                },
                settings: {
                    ...config.route.settings,
                    createOrganization(orgConfig) {
                        const orgRoute =  config.route.settings.createOrganization(orgConfig);
                        return {
                            ...orgRoute,
                            Redirect: () => {
                                return <Navigate to='subscription' replace={true} />
                            }
                        }
                        
                    },
                }
            },
            controller: {
                ...config.controller,
                settings: {
                    ...config.controller.settings,
                    createOrganization() {
                        const controller =
                            config.controller.settings.createOrganization();
                        return {
                            useProps() {
                                const props = controller.useProps();
                                const {
                                    data: { account },
                                } = useAccountContextV2();
                                if(account.details.kind === 'organization') {
                                    return props;
                                }
                                const allowdRouteKeys = new Set<string>([
                                    'subscription',
                                    'payment',
                                ]);
                                return {
                                    ...props,
                                    isVisible(item) {
                                        if(props.isVisible(item)) {
                                            if (allowdRouteKeys.has(item.key)) {
                                                return props.isVisible(item);
                                            }
                                        }
                                        return false;
                                    },
                                };
                            },
                        };
                    },
                },
            },
        });
    };
}
