import { useMemo } from 'react';
import { chain } from 'lodash';
import { useWorkspaceContextV2 } from '../../../context';
import { PeerStatusRepository, PeerGroupRepository, PluginConnectionRepository, PluginRepository } from '../../../app';
import { PluginSlugs } from '../../../../config';
import { AnyResolvedType } from '../../../domain/attributes';
import { IsPluginAvailable, PluginEntity, PluginHelpers } from '../../../domain';
import { isCustomized, isPeerGroupConfigured, PeerGroup } from '../../../domain/peers';
import {
    OnboardingPeerGroupAggregate,
    OnboardingPeerGroupData,
} from './onboardingPeerGroupModel';
import { OnboardingPeerGroupLoader } from './onboardingPeerGroupInterface';
import { OnboardingPeerGroupContainerConfig } from './onboardingPeerGroupConfig';
import { IntegrationService } from '../../../service/assets';
import { isLoadingCollection } from '../../../base';
import { PluginConnection } from '../../../domain/metrics';
import { PluginServiceV2 } from '../../../service';

export function createOnboardingPeerGroupLoader(
    config: OnboardingPeerGroupContainerConfig,
    repository: {
        competitive: PeerStatusRepository;
        peergroups: PeerGroupRepository;
        connection: PluginConnectionRepository;
    },
    service: {
        integration: IntegrationService;
        plugin: PluginServiceV2
    }
): OnboardingPeerGroupLoader {
    return {
        useData(context, state): OnboardingPeerGroupData {
            
            const plugins = service.plugin.useResolvedList(context, {}, {suspense: true});
            const connections = repository.connection.useFind(context,
                {
                    workspaces: [context.workspace],
                },
                {
                    keepPreviousData: true,
                    staleTime: Infinity,
                }
            );

            const peergroups = repository.peergroups.useFind(
                context, {workspace: context.workspace, plugins: plugins.data?.map(x => x.id) || [] });
            
            const integrations = service.integration.useFind(context, {});

            const connectionsByPlugin = useMemo(
                () =>
                    connections.data?.reduce(
                        (acc, item) => ({ ...acc, [item.plugin.id]: item }),
                        {} as Record<string, PluginConnection | undefined>
                    ) ?? {},
                [connections.data]
            );

            const pluginsById = useMemo(
                () =>
                    plugins.data?.reduce(
                        (acc, plugin) => ({
                            ...acc,
                            [plugin.id]: plugin,
                        }),
                        {} as Record<string, PluginEntity<AnyResolvedType> | undefined>
                    ) ?? {},
                [plugins.data]
            );

            const availablePlugins = useMemo(() => {
                const initial = new Set<string>();
                return (
                    plugins.data?.reduce((acc, plugin) => {
                        const connection = connectionsByPlugin[plugin.id];
                        if (!connection) {
                            return acc;
                        }
                        const isInstalled = PluginHelpers.isInstalled({
                            plugin,
                            connection,
                        });
                        if (!isInstalled) {
                            return acc;
                        }
                        acc.add(plugin.id);
                        return acc;
                    }, initial) ?? initial
                );
            }, [plugins.data, connectionsByPlugin]);

            const groupsByPlugin = useMemo(
                () =>
                    peergroups.data?.reduce(
                        (acc, group) => ({
                            ...acc,
                            [group.plugin.id]: group,
                        }),
                        {} as Record<string, PeerGroup | undefined>
                    ) ?? {},
                [peergroups.data]
            );

            // console.log('availablePlugins', availablePlugins);
            // console.log(connections.data);

            const aggregates = chain(connections.data ?? [])
                .filter((item) => {
                    // console.log('item', item);
                    // const aggregate = integrationsByKind[ite]
                    return (
                        availablePlugins.has(item.plugin.id) &&
                        item.plugin.id !== 'media-mix'
                    );
                })
                // .filter(
                //     (connection) =>
                //         connection.status !== 'ready' &&
                //         // only include mapped plugins
                //         // !!PluginSlugs[connection.plugin]
                //         connection.plugin !== 'media-mix'
                // )
                .uniqBy((connection) => connection.plugin)
                .flatMap((connection): OnboardingPeerGroupAggregate[] => {
                    const plugin = pluginsById[connection.plugin.id];
                    if (!plugin) {
                        console.info(
                            `no plugin found for plugin ${connection.plugin.id}`
                        );
                        return [];
                    }
                    const peergroup = groupsByPlugin[plugin.id];
                    if (!peergroup) {
                        console.info(`no peergroup found for plugin ${plugin.id}`);
                        return [];
                    }
                    return [
                        {
                            status: isCustomized(peergroup) ? 'configured' : 'pending',
                            plugin,
                            connection,
                            peergroup,
                        },
                    ];
                })
                .value();
            // z;

            const updatePeerGroup = repository.peergroups.useUpsert(context);

            // if (aggregates.length === 0) {
            //     // throw new Error(
            //     //     `expected workspace entities to have been loaded with suspense`
            //     // );
            //     return {
            //         error: true,
            //         title: `Asset is not ready`,
            //         description: `The asset does not yet have any mapped integrations`,
            //     };
            // }

            return {
                isLoading:
                    connections.isLoading ||
                    peergroups.isLoading ||
                    plugins.isLoading ||
                    isLoadingCollection(integrations),
                error: false,
                items: aggregates,
                query: {
                    connections,
                    peergroups,
                    plugins,
                },
                mutation: {
                    updatePeerGroup,
                },
            };
        },
    };
}
