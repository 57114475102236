import { compose } from 'lodash/fp';
import { KernelConfig, KernelInit } from './kernelConfig';
import { Kernel, KernelEnhancer } from './kernelInterface';
import { createErrorController } from './error';
import { createActionItemControllerV2 } from '../view/common';
import { createAuthenticator } from './authenticator';
import { createToast } from './toast';
import { createBrowserController } from './browser';
import { createNavigationController } from './navigation';
import { createHelp, createHelpController } from './help';

export function configureKernel(config: KernelInit, enhancer?: KernelEnhancer) {
    const enhancers = enhancer ? [enhancer] : [];
    return create(
        {
            ...config,
            provider: {
                ...config.provider,
                createErrorController: createErrorController,
                createActionController: createActionItemControllerV2,
                createBrowserController: createBrowserController,
                createNavigationController: createNavigationController,
                createHelp: createHelp,
                createAuthenticator: createAuthenticator,
                createToast: createToast,
            },
        },
        compose(...enhancers)
    );
}

function create(config: KernelConfig, enhancer?: KernelEnhancer): Kernel {
    if (enhancer) {
        return enhancer(create)(config);
    }

    const authenticator = config.provider.createAuthenticator({
        adapter: config.adapter.authenticator,
    });
    const toaster = config.provider.createToast({});

    return {
        controller: {
            browser: config.provider.createBrowserController(),
            navigation: config.provider.createNavigationController(config.navigation),
        },
        adapter: {
            authenticator: config.adapter.authenticator,
            help: config.help.adapter,
        },
        provider: config.provider,
        infra: {
            ...config.infra,
            authenticator,
            toasterV2: toaster,
            help: config.provider.createHelp(config.help),
        },
    };
}
