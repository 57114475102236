import { useQuery, useQueryClient } from '@tanstack/react-query';
import { assert } from '../../../util/assert';
import { DatasetAdapter } from './datasetAdapter';
import { DatasetRepository } from './datasetInterface';

export function createDatasetRepository(adapter: DatasetAdapter): DatasetRepository {
    return {
        useFind(context, query, options) {
            const queryClient = useQueryClient();
            const result = useQuery({
                ...options,
                queryKey: ['dataset', 'find', context.account, query],
                async queryFn() {
                    const response = await adapter.find(context, query);
                    return response;
                },
                onSuccess(data) {
                    for (const row of data) {
                        queryClient.setQueryData(
                            ['dataset', 'lookup', context.account, row.id],
                            row
                        );
                    }
                },
            });
            return result;
        },
        useLookup(context, query, options) {
            const result = useQuery({
                ...options,
                enabled: query !== null,
                queryKey: ['dataset', 'lookup', context.account, query?.id],
                async queryFn() {
                    assert(query, 'expected disabled query');
                    const response = await adapter.lookup(context, query);
                    return response;
                },
            });
            return result;
        },
    };
}
