import { useToken } from '@chakra-ui/react';
import { Elements, useElements, useStripe } from '@stripe/react-stripe-js';
import { StripeElementsOptions } from '@stripe/stripe-js';
import { loadStripe, Stripe } from '@stripe/stripe-js';
import { useMutation } from '@tanstack/react-query';
import React, { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useLocation } from 'react-router';
import { assert } from '../../../../../../util/assert';
import { SettingsBillingContainerConfig } from '../../base/billingBaseConfig';
import { buildElementsApperance } from './paymentMethodEditFactory';
import {
    PaymentMethodEditController,
    PaymentMethodEditLoader,
    PaymentMethodEditService,
} from './paymentMethodEditInterface';
import {
    PaymentMethodEditContainerProps,
    PaymentMethodEditServiceProps,
    PaymentMethodEditViewProps,
} from './paymentMethodEditProps';
import { PaymentMethodEditUrlParamsSchema } from './paymentMethodEditSchema';


function createPaymentContainer(
    config: SettingsBillingContainerConfig,
    loader: PaymentMethodEditLoader,
    controller: PaymentMethodEditController,
    service: PaymentMethodEditService,

    stripePromise: Promise<Stripe | null>,
    View: React.FC<
        { children?: React.ReactNode | undefined } & PaymentMethodEditViewProps
    >
    
): React.FC<
{ children?: React.ReactNode | undefined } & PaymentMethodEditContainerProps> {
    const {
        context: { useContext },
        infra: {
            toaster: { useToast },
            options: { useOptions },
        },
    } = config;
    return ({ children, ...containerProps }) => {
        const toast = useToast();
        const options = useOptions(PaymentMethodEditUrlParamsSchema);
        const elements = useElements();
        const stripe = useStripe();
        const location = useLocation();
        
        // if (!stripe || !elements) {
        //     // console.log('waiting for stripe ', stripe, elements, stripePromise);
        //     // trigger suspense while loading stripe dependencies
        //     throw stripePromise;
        //     // return
        // }
        // console.log('options', options);
        // console.log('elements', elements);

        const context = useContext();
        const data = loader.useLoad(context);

        const mutation = useMutation({
            async mutationFn(props: PaymentMethodEditServiceProps) {
                const response = await service.run(props, options);
                return response;
            },
        });
        const props = controller.useProps(context, data, {
            elements,
            form: {
                id: 'payment_method_form',
                form: useForm({
                    defaultValues: {},
                }),
                async onSubmit(values) {
                    if(!props.elements || !stripe) {
                        throw new Error('failed to submit because stripe was not loaded properly');
                    }
                    const { error: stripeFormError } = await props.elements.submit();
                    if (stripeFormError) {
                        throw stripeFormError;
                    }
                    
                    await mutation.mutateAsync({
                        location,
                        window,
                        elements: props.elements,
                        stripe,
                        clientSecret: data.setupIntent.clientSecret,
                    });
                    toast({
                        kind: 'success',
                        description: `Successfully updated payment method`,
                    });
                },
                onSubmitError(error) {
                    toast({
                        kind: 'error',
                        description: error.message,
                    });
                },
            },
        });
        return <View {...props}>{children}</View>;
    };
}

export function createPaymentMethodEditContainer(
    config: SettingsBillingContainerConfig,
    loader: PaymentMethodEditLoader,
    controller: PaymentMethodEditController,
    service: PaymentMethodEditService,
    View: React.FC<
        { children?: React.ReactNode | undefined } & PaymentMethodEditViewProps
    >
): React.FC<
    { children?: React.ReactNode | undefined } & PaymentMethodEditContainerProps
> {
    const {
        context: { useContext },
        config: { stripeKey }
    } = config;

    const stripePromise = loadStripe(stripeKey);


    const Container = createPaymentContainer(
        config,loader, controller, service, stripePromise, View
    );
    return ({ children, ...containerProps }) => {
        const context = useContext();
        const data = loader.useLoad(context);
        const [backgroundColor, baseColor, invalidColor, placeholderColor] = useToken(
            'colors',
            ['transparent', 'whiteAlpha.900', 'red.300', 'whiteAlpha.400']
        );
        

        const elements: StripeElementsOptions = {
            loader: 'always',
            clientSecret: data.setupIntent.clientSecret,
            appearance: buildElementsApperance(data, {
                primaryColor: useToken('colors', 'blue.200'),
                backgroundColor,
                baseColor,
                placeholderColor,
                invalidColor,
                borderColor: useToken('colors', 'whiteAlpha.400'),
            }),
        };

        return (
            <Elements stripe={stripePromise} options={elements}>
                <Container {...containerProps}>{children}</Container>
            </Elements>
        );
    };
}
