import { useBreakpointValue, useColorMode } from '@chakra-ui/react';
import { BrowserConfig } from './browserConfig';
import { BrowserController } from './browserInterface';

export function createBrowserController(config: BrowserConfig = {}): BrowserController {
    return {
        useProps(props) {
            const mode =
                useBreakpointValue({ base: 'mobile', md: 'desktop' } as const) ??
                ('desktop' as const);
            const { colorMode } = useColorMode();
            return { mode, color: colorMode };
        },
    };
}
