import { useQuery } from '@tanstack/react-query';
import { AxiosInstance } from 'axios';
import React from 'react';
import { fetchMembers } from '../../../api';
import { useWorkspaceApi } from '../../app';
import { AuthenticationV2 } from '../../domain';
import { DashboardSlugs } from '../../../config/dashboard';
import { ApplicationEntryEnhancer } from '../../entrypoint';
import { FormatEnhancerConfig } from './formatConfig';
import { FormatContextValue } from './formatInterface';
import { createFormatController } from './formatController';
import {
    COUNTRY_KEY,
    COUNTRY_TYPE_SLUG,
    createProperties,
    VERTICAL_KEY,
} from './formatConstant';

export function createFormatEnhancer(
    init: FormatEnhancerConfig
): ApplicationEntryEnhancer {
    const FormatContext = React.createContext<null | FormatContextValue>(null);

    function createFormatProvider(config: {
        useAuth(): AuthenticationV2;
        axios: AxiosInstance;
    }): React.FC<
        { children?: React.ReactNode | undefined } & { children?: React.ReactNode }
    > {
        return (props) => {
            const { scheme } = config.useAuth();
            if (scheme.kind !== 'legacy') {
                throw new Error(`expected app auth`);
            }
            const workspace = useWorkspaceApi();
            const workspaceEntity = workspace.getWorkspace();
            const workspaceId = workspaceEntity.id as number;
            const enabled = workspaceEntity.claim.kind === 'active';
            const query = useQuery({
                queryKey: ['format', 'types', workspaceId],
                async queryFn() {
                    // this is a hack. we should implement a dynamic bulk endpoint for peer group enums
                    const [responseVertical, responseCountries] = await Promise.all([
                        fetchMembers(
                            config.axios,
                            VERTICAL_KEY,
                            workspaceId,
                            // passing the dashboard is hack. we need to be able to fetch members outside the scope of a specific dashboard.
                            // here we assume that the facebook dashboard verticals contain most if not all vertical enum members
                            null,
                            {
                                headers: {
                                    Authorization: `bearer ${scheme.store.authToken}`,
                                },
                            }
                        ),
                        fetchMembers(
                            config.axios,
                            COUNTRY_TYPE_SLUG,
                            workspaceId,
                            // passing the dashboard is hack. we need to be able to fetch members outside the scope of a specific dashboard.
                            // here we assume that the facebook dashboard verticals contain most if not all vertical enum members
                            null,
                            {
                                headers: {
                                    Authorization: `bearer ${scheme.store.authToken}`,
                                },
                            }
                        ),
                    ]);
                    const properties = createProperties({
                        enum: {
                            countries: responseCountries.map((item) => ({
                                label: item.name,
                                value: item.value,
                            })),
                            verticals: responseVertical.map((item) => ({
                                label: item.name,
                                value: item.value,
                                children:
                                    item.children?.map((item) => ({
                                        label: item.name,
                                        value: item.value,
                                    })) ?? [],
                            })),
                        },
                    });
                    // console.log('properties', properties);
                    return properties;
                },
                suspense: true,
                staleTime: Infinity,
                retry: false,
                enabled,
            });

            const value: FormatContextValue = {
                properties: query.data ?? [],
            };
            return (
                <FormatContext.Provider value={value}>
                    {props.children}
                </FormatContext.Provider>
            );
        };
    }

    return (create) => (config) => {
        const formatController = createFormatController({
            hooks: {
                useValueFormatter: config.infra.createFormatter,
            },
        });
        const instance = create({
            ...config,
            provider: {
                ...config.provider,
                custom: [...config.provider.custom],
            },
            route: {
                ...config.route,
                createAccountWorkspaceRoute(routeConfig) {
                    const Route =
                        config.route.createAccountWorkspaceRoute(routeConfig);
                    const FormatProvider = createFormatProvider({
                        useAuth: routeConfig.hook.useAuth,
                        axios: config.infra.createAxios({
                            defaults: {},
                            async getToken() {
                                return null;
                            },
                        }),
                    });
                    return (props) => {
                        return (
                            <FormatProvider>
                                <Route {...props} />
                            </FormatProvider>
                        );
                    };
                },
            },
            module: {
                ...config.module,
                createMetricModule(metricConfig) {
                    return config.module.createMetricModule({
                        ...metricConfig,
                        provider: {
                            ...metricConfig.provider,
                            benchmark: {
                                list: {
                                    ...metricConfig.provider.benchmark.list,
                                    createController(controllerConfig, loader) {
                                        const controller =
                                            metricConfig.provider.benchmark.list.createController(
                                                controllerConfig,
                                                loader
                                            );
                                        return {
                                            useProps(...args) {
                                                const props = controller.useProps(
                                                    ...args
                                                );

                                                const context =
                                                    React.useContext(FormatContext);
                                                if (!context) {
                                                    return props;
                                                }

                                                const control =
                                                    formatController.useProps(context);

                                                return {
                                                    ...props,
                                                    getNameLabel(condition) {
                                                        return control.formatKey(
                                                            condition
                                                        );
                                                    },
                                                    getOperatorLabel(condition) {
                                                        return control.formatOperator(
                                                            condition
                                                        );
                                                    },
                                                    getValueLabel(condition) {
                                                        return (
                                                            control.tryFormatValue(
                                                                condition
                                                            ) ??
                                                            props.getValueLabel(condition)
                                                        );
                                                    },
                                                };
                                            },
                                        };
                                    },
                                },
                            },
                        },
                    });
                },
            },
        });
        return instance;
    };
}
