import { assert } from '../../../util/assert';
import { ConversationRunAdapter } from '../../../app/assistant';
import { ConversationRunImplConfig } from './runImplConfig';

export function createConversationRunImpl(
    config: ConversationRunImplConfig
): ConversationRunAdapter {
    const {
        api: { assistant: api },
    } = config;

    return {
        async create(context, props) {
            assert(context.auth.scheme.kind === 'legacy', 'expected legacy auth');
            await context.auth.scheme.store.waitAuthenticated();
            assert(context.auth.scheme.store.authToken, 'missing token');
            const response = await api.runCreate(
                {
                    runCreatePayload: {
                        thread: props.thread.id,
                        messages: [
                            {
                                role: 'user',
                                content: props.message.blocks,
                            },
                        ],
                    },
                },
                {
                    headers: {
                        Authorization: `bearer ${context.auth.scheme.store.authToken}`,
                    },
                    timeout: 2 * 60 * 1000,
                }
            );
            return response.data;
        },
    };
}
