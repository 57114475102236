import { AcceptOrganizationFormValues } from './organization';
import { AcceptProfileFormValues } from './profile';
import { RegistrationAcceptCreateContainerProps } from './registrationAcceptCreateProps';
import { RegistrationAcceptCreateAggregate } from './registrationAcceptCreateModel';

export function buildInitialProfileFormValues(
    item: RegistrationAcceptCreateAggregate
): AcceptProfileFormValues {
    return {
        email: item.invitation.email,
        firstname: item.invitation.first_name ?? '',
        lastname: item.invitation.last_name ?? '',
        title: (item.invitation.kind === 'member' ? item.invitation.memberProfile?.title: null) ?? '',
    };
}

export function buildInitialOrganizationFormValues(
    item: RegistrationAcceptCreateAggregate
): AcceptOrganizationFormValues | null {
    if(item.invitation.kind === 'external') {
        if (item.invitation.account.kind === 'organization') {
            return {
                name: item.invitation.account.name,
                website: item.invitation.account.domain,
            }
        }
    }
    return null;
}
