import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Kernel, ServiceUnavailableError } from '../../../base';
import { ConversationRunAdapter } from './conversationRunAdapter';
import {
    ConversationRunRepository,
    ConversationRunCreateMutation,
} from './conversationRunInterface';
import { MessageRunCreateProps } from './conversationRunProps';
import { ConversationRunEntity } from './conversationRunModel';
import { UserScope } from '../../platform';
import { getMessageQueryKey, MessageEntity } from '../message';

export function createConversationRunRepository(
    kernel: Kernel,
    adapter: ConversationRunAdapter
): ConversationRunRepository {
    const PREFIX = ['conversations', 'run'];

    // Helper function to generate consistent query keys
    const getQueryKey = (context: UserScope) => [...PREFIX, context.auth.user];

    return {
        useCreate(context): ConversationRunCreateMutation {
            const client = useQueryClient();
            const queryKey = getQueryKey(context);

            interface MutationContextType {
                previousMessages: MessageEntity[] | undefined;
                optimisticMessages: MessageEntity[];
            }

            const result = kernel.infra.repository.useMutation<
                ConversationRunEntity,
                Error,
                MessageRunCreateProps,
                MutationContextType
            >(
                {
                    organization: null,
                    principal: context.auth.user
                        ? {
                              kind: 'user',
                              id: context.auth.user.id,
                          }
                        : null,
                },
                {
                    mutationFn: async (props) => {
                        const response = await adapter.create(context, props);
                        // throw new Error('somerthing unexpected went wrong');
                        return response;
                    },
                    onMutate: async (props): Promise<MutationContextType> => {
                        const messageQueryKey = getMessageQueryKey(context, {
                            thread: props.thread,
                        });

                        await client.cancelQueries({ queryKey });
                        await client.cancelQueries({ queryKey: messageQueryKey });

                        const previousMessages =
                            client.getQueryData<MessageEntity[]>(messageQueryKey);

                        const now = new Date();
                        // Create the optimistic messages with unique temporary IDs
                        const optimisticMessages = [props.message].map(
                            (item, index): MessageEntity => ({
                                id: `optimistic-${Date.now()}-${index}`, // Unique temporary ID
                                object: 'message',
                                role: 'user',
                                thread: props.thread.id,
                                content: item.blocks,
                                created_at: now,
                            })
                        );

                        // Update the cache with the optimistic messages
                        client.setQueryData<MessageEntity[]>(
                            messageQueryKey,
                            (oldMessages = []) => [...optimisticMessages, ...oldMessages]
                        );

                        return { previousMessages, optimisticMessages };
                    },
                    onError: async (error, variables, querycontext) => {
                        const messageQueryKey = getMessageQueryKey(context, {
                            thread: variables.thread,
                        });
                        if (querycontext?.previousMessages) {
                            client.setQueryData(
                                messageQueryKey,
                                querycontext.previousMessages
                            );
                        }
                        if (!(error instanceof ServiceUnavailableError)) {
                            await client.invalidateQueries({ queryKey });
                            await client.invalidateQueries({ queryKey: messageQueryKey });
                        }
                    },
                    onSuccess: (data, variables, querycontext) => {
                        const messageQueryKey = getMessageQueryKey(context, {
                            thread: variables.thread,
                        });
                        // Assume 'data' includes 'messages' array
                        const serverMessages = data.messages; // Adjust according to your data structure
                        const optimisticIds = new Set(
                            querycontext?.optimisticMessages.map((msg) => msg.id)
                        );

                        client.setQueryData<MessageEntity[]>(
                            messageQueryKey,
                            (messages = []) => {
                                // Remove optimistic messages
                                const messagesWithoutOptimistic = messages.filter(
                                    (message) => !optimisticIds.has(message.id)
                                );
                                // Add server messages
                                return [
                                    // NOTE then messages included on the run object are in ascending order
                                    // so we need to reverse them because the message collection is in descending order
                                    ...serverMessages.slice().reverse(),
                                    ...messagesWithoutOptimistic,
                                ];
                            }
                        );
                    },
                    onSettled: (data, error, variables) => {
                        const messageQueryKey = getMessageQueryKey(context, {
                            thread: variables.thread,
                        });
                        client.invalidateQueries({ queryKey });
                        client.invalidateQueries({ queryKey: messageQueryKey });
                    },
                }
            );

            return result;
        },
    };
}
