import { InvitationAcceptPayload } from '../../../../domain/accounts';
import { RegistrationInvitationContext } from '../context';
import { AcceptProfileFormValues } from '.';
import { RegistrationAcceptCreateContainerProps } from '../registrationAcceptCreateProps';

export function buildServicePayload(
    context: RegistrationInvitationContext,
    props: RegistrationAcceptCreateContainerProps & {
        profile: AcceptProfileFormValues;
    }
): InvitationAcceptPayload {
    return {
        token: props.token,
        invitation_id: props.invitation.id,
        email: props.profile.email,
        first_name: props.profile.firstname,
        last_name: props.profile.lastname,
        member_profile: context.data.invitation.kind === 'member' ? context.data.invitation.memberProfile : null,
    };
}
