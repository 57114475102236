import { WorkspaceRedirectController } from './workspaceRedirectInterface';


export const createWorkspaceRedirectController = (): WorkspaceRedirectController => ({
    useProps(props) {
        if(props.workspace?.claim.kind === 'inactive') {
            return { to: `/u/assets/${props.workspace.id}/settings/general-settings` }
        }
        if(props.workspace) {
            return {
                to: `/u/assets/${props.workspace.id}/home`
            }

        }
        if(props.account.details.kind === 'user') {
            return {
                to: '/u/account/reporting'
            }
        }
        return { to: null };
    },
})